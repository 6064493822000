import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import { useSnackContext, useSnackDispatchContext } from '../contexts/NotificationBarContext';

export default function SimpleSnackbar() {
    const snackData = useSnackContext();
    const dispatch: any = useSnackDispatchContext();
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        dispatch({ type: "hide" });
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                open={snackData.open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
            >
                <Alert severity={snackData.severity}>
                    {`${snackData.msg}`}
                </Alert>
            </Snackbar>
        </div>
    );
}
