import { Button, Grid, TextField, Typography, Zoom } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React from 'react';
import UseRadioGroup from '../components/RadioGroup';
import SelectBox from '../components/SelectBox';
import { useSnackDispatchContext } from '../contexts/NotificationBarContext';
import { authenticateUser, registerUser } from '../services/webauthAuthenticateUser';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: 20,
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Passwordless = () => {

    const [username, setUsername] = React.useState("")
    const [radioButtonValue, setRadioButtonValue] = React.useState("")
    const [authenticationVerificationSettingValue, setValue] = React.useState("required")
    const snackDispatch: any = useSnackDispatchContext();
    const handleRadioButton = (authenticationVerificationSettingValue: string) => {
        setRadioButtonValue(authenticationVerificationSettingValue);
    }

    return (<>
        <Grid sx={{ flexGrow: 1 }} direction={"row"} container justifyContent={"center"} alignItems={"center"}>
            <Grid maxWidth={500} container  >
                <Grid item xs={12}>
                    <Item elevation={0}>
                        <Typography variant='h4'>AShield WebAuth Demo</Typography>
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item elevation={0}>
                        <TextField value={username} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setUsername(event.target.value) }} sx={{ minWidth: 300 }} fullWidth label="Username" />
                    </Item>
                </Grid>
                <Grid item xs={12}>
                    <Item elevation={0}>
                        <UseRadioGroup items={["Registration", "Authentication"]} onChangeCallback={v => handleRadioButton(v)}></UseRadioGroup>
                    </Item>
                </Grid>

                <Grid item xs={12}>
                    <Item elevation={0}>
                        <Button fullWidth onClick={() => {
                            radioButtonValue === "Authentication" ?
                                authenticateUser(username, authenticationVerificationSettingValue,
                                    (error_code: string, msg: string) => { console.log(`Error code: ${error_code}, msg:${msg}`); snackDispatch({ type: "showFailed", msg: "Authentication failed" }) },
                                    (str: string) => { snackDispatch({ type: "show", msg: `Welcome back ${username}` }) })
                                : registerUser(
                                    username,
                                    (error_code: string, msg: string) => { console.log(`Error code: ${error_code}, msg:${msg}`);; snackDispatch({ type: "showFailed", msg: "Registration failed" }) },
                                    (str: string) => { snackDispatch({ type: "show", msg: `Registration completed` }) }
                                );
                        }} sx={{ marginX: 0, minWidth: 120 }} variant="contained">Submit</Button>
                    </Item>
                </Grid>
                {radioButtonValue === "Authentication" ?
                    <Zoom in={radioButtonValue === "Authentication"}>
                        <Grid item xs={12}>
                            <Item elevation={0}>
                                <Typography variant='body1' sx={{ py: 2, textAlign: "left", textDecoration: "underline" }}>Authentication Settings</Typography>
                                <SelectBox label='User Verification' items={['discouraged', 'required']} value={authenticationVerificationSettingValue} setValue={(str) => setValue(str)} />
                            </Item>
                        </Grid>
                    </Zoom>
                    :
                    <></>}
            </Grid>
        </Grid>
    </>);
}

export default Passwordless;